exports.components = {
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-formats-js": () => import("./../../../src/pages/formats.js" /* webpackChunkName: "component---src-pages-formats-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-logo-js": () => import("./../../../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-meta-js": () => import("./../../../src/pages/meta.js" /* webpackChunkName: "component---src-pages-meta-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-oss-js": () => import("./../../../src/pages/oss.js" /* webpackChunkName: "component---src-pages-oss-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-update-js": () => import("./../../../src/pages/payment/update.js" /* webpackChunkName: "component---src-pages-payment-update-js" */),
  "component---src-pages-pdf-js": () => import("./../../../src/pages/pdf.js" /* webpackChunkName: "component---src-pages-pdf-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-screenshot-js": () => import("./../../../src/pages/screenshot.js" /* webpackChunkName: "component---src-pages-screenshot-js" */),
  "component---src-pages-sdk-js": () => import("./../../../src/pages/sdk.js" /* webpackChunkName: "component---src-pages-sdk-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-user-agents-js": () => import("./../../../src/pages/user-agents.js" /* webpackChunkName: "component---src-pages-user-agents-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

